import style from "./index.css";

const Preloader = () => (
  <div class={style.loader_container}>
    <div class={style.loader}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Preloader;